import { useLocation } from "@remix-run/react";
import type { PostHog } from "posthog-js";
// @ts-expect-error // Weird vite / importing directory / node
import { usePostHog } from "posthog-js/react/dist/umd";
import { useEffect } from "react";

const useLocationAnalytics = () => {
  const posthog: PostHog = (usePostHog as () => PostHog)();
  const location = useLocation();

  useEffect(() => {
    posthog.capture("$pageview"); // new
  }, [location.pathname, location.search, posthog]);
};

export default useLocationAnalytics;
