import type { PostHog } from "posthog-js";
// @ts-expect-error // Weird vite / importing directory / node
import { usePostHog } from "posthog-js/react/dist/umd";
import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

import { useOptionalCompany } from "../../hooks/useCompany.js";

/** Fire effects to keep 3rd party tools up to date as user navigates */
const useCompanyAnalytics = () => {
  const posthog: PostHog = (usePostHog as () => PostHog)();
  const intercom = useIntercom();
  const company = useOptionalCompany();

  useEffect(() => {
    if (company) {
      posthog.group("company", company.id, {
        id: company.id,
        name: company.name,
        slug: company.slug,
      });
    } else {
      posthog.resetGroups();
    }
  }, [company, posthog]);

  useEffect(() => {
    if (company) {
      intercom.update({
        company: {
          companyId: company.id,
          name: company.name,
        },
      });
    } else {
      intercom.update({
        company: undefined,
      });
    }
  }, [intercom, company]);
};

export default useCompanyAnalytics;
